window.addEventListener( 'submit', async( event: Event ) => {
	if ( !event.target ) {
		return;
	}

	const form = event.target as HTMLFormElement;

	if ( !form.hasAttribute( 'enquire-form' ) ) {
		return; /* not for us */
	}

	event.stopPropagation();
	event.preventDefault();

	const inputs = form.elements;
	const data = new FormData( form );

	enquireSetSubmittingState( form, inputs );

	try {
		// Submit
		const resp: Response = await fetch( '/wp-json/form/enquire.json', {
			method: 'post',
			body: data,
		} );

		// Check if HTTP was ok
		if ( !resp.ok ) {
			form.setAttribute( 'data-state', 'errored' ); /* show the general form error message */
		}

		// Get json response data
		const respData = await resp.json();
		if ( !respData ) {
			form.setAttribute( 'data-state', 'errored' ); /* show the general form error message */

			return;
		}

		const enquireResponse = respData as EnquireResponse;
		if ( enquireResponse.success ) {
			form.setAttribute( 'data-state', 'success' ); /* show the general form success message */

			return;
		}

		/* Set field error area contents */
		if ( !enquireResponse.success ) {
			/* Disable inputs and submit button */
			for ( let i = 0; i < inputs.length; i++ ) {
				inputs[i].removeAttribute( 'disabled' );
			}

			form.setAttribute( 'data-state', 'errored' );
			if ( enquireResponse.fields ) {
				for ( const key in enquireResponse.fields ) {
					const field = enquireResponse.fields[key];
					if ( field.error ) {
						const fieldErrorArea = form.querySelector( `[error-area-for="${key}"]` );

						if ( fieldErrorArea ) {
							fieldErrorArea.removeAttribute( 'hidden' );
							fieldErrorArea.innerHTML = field.error;
						}
					}
				}
			}

			/* Set general errors */
			if ( enquireResponse.errors && enquireResponse.errors.length ) {
				const formErrorArea = form.querySelector( '[error-area]' );
				if ( formErrorArea ) {
					formErrorArea.innerHTML = enquireResponse.errors.join( '<br>' );
				}
			}

			return;
		}

	} catch ( err ) {
		form.setAttribute( 'data-state', 'errored' );
		console.log( '[enquire-form] : err - ' + err.message );
	}
} );

function enquireSetSubmittingState( form: HTMLFormElement, inputs: HTMLFormControlsCollection ) {
	form.setAttribute( 'data-state', 'submitting' ); /* show the loading/progress state */

	/* Reset the field error area's */
	form.querySelectorAll( '[error-area-for]' ).forEach( ( fieldErrorArea ) => {
		fieldErrorArea.setAttribute( 'hidden', '' );
		fieldErrorArea.innerHTML = '';
	} );

	/* Reset the general field error area */
	const formErrorArea = form.querySelector( '[error-area]' );
	if ( formErrorArea && formErrorArea.hasAttribute( 'original-error-message' ) ) {
		formErrorArea.innerHTML = formErrorArea.getAttribute( 'original-error-message' ) || 'Something went wrong.';
	}

	/* Disable inputs and submit button */
	for ( let i = 0; i < inputs.length; i++ ) {
		inputs[i].setAttribute( 'disabled', '' );
	}
}

type EnquireResponse = {
	submitted?: boolean,
	success?: boolean,
	errors?: Array<string>,
	fields?: Record<string, {
		label: string,
		name: string,
		required: boolean,
		type: 'email' | 'text' | 'textarea' | 'hidden',
		value: string | number | null,
		error?: string
	}>
};
