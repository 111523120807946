import { MrModalDialog } from '@mrhenry/wp--mr-interactive';

interface ArtworkCarouselElement extends HTMLElement {
	goToIndex ( index: number ): void
}

class MrArtworkOverlay extends MrModalDialog {
	override willOpen() {
		super.willOpen();
		document.body.classList.add( 'is-showing-overlay' );

		return Promise.resolve();
	}

	override willClose() {
		super.willClose();
		document.body.classList.remove( 'is-showing-overlay' );

		return Promise.resolve();
	}

	override updateState( directive: string ) {
		const splitDirective = directive.split( ':' );
		const dialogDirective = splitDirective[0];

		super.updateState( dialogDirective );

		const carouselIndex = splitDirective[1];
		if ( !carouselIndex ) {
			return Promise.resolve();
		}

		const carousel = this.querySelector( 'mr-artwork-carousel' ) as ArtworkCarouselElement;

		if ( !carousel || 'function' !== typeof carousel.goToIndex ) {
			return Promise.resolve();
		}

		carousel.goToIndex( parseInt( carouselIndex, 10 ) );

		return Promise.resolve();
	}
}

customElements.define( 'mr-artwork-overlay', MrArtworkOverlay );
