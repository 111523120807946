class MrArtworkCarousel extends HTMLElement {
	// MARK : properties

	#currentIndex = 0;

	#clickHandler = ( e: MouseEvent ): void => {
		// ignore clicks with modifier keys : shift, ctrl, alt,...
		if ( e.metaKey ) {
			return;
		}


		// Check if target exist and is instance of htmlelement.
		if ( !e.target || !( e.target instanceof HTMLElement ) ) {
			return;
		}

		// Unknown trigger, not handling this event.
		if (
			!e.target.hasAttribute( 'data-carousel-next' ) &&
			!e.target.hasAttribute( 'data-carousel-previous' )
		) {
			return;
		}

		e.preventDefault();
		e.stopPropagation();

		if ( e.target.hasAttribute( 'data-carousel-next' ) ) {
			this.goToNextItem();

			return;
		}

		if ( e.target.hasAttribute( 'data-carousel-previous' ) ) {
			this.goToPreviousItem();

			return;
		}
	};

	// MARK : lifecycle

	connectedCallback() {
		this.addEventListener( 'click', this.#clickHandler );
	}

	disconnectedCallback() {
		this.removeEventListener( 'click', this.#clickHandler );

		// Reset States
		this.#currentIndex = 0;
	}

	// MARK : methods

	public goToIndex( index: number ) {
		if ( !index || 0 > index || isNaN( index ) ) {
			return;
		}

		if ( index === this.#currentIndex ) {
			return;
		}


		this.#currentIndex = index;
		this.navigate( index );
	}

	private goToPreviousItem() {
		const items = this.querySelectorAll( '[data-carousel-item]' );
		const newIndex = this.indexMinusOne( this.#currentIndex, items.length, true );
		if ( newIndex === this.#currentIndex ) {
			return;
		}


		this.#currentIndex = newIndex;
		this.navigate( newIndex );
	}

	private goToNextItem() {
		const items = this.querySelectorAll( '[data-carousel-item]' );
		const newIndex = this.indexPlusOne( this.#currentIndex, items.length, true );
		if ( newIndex === this.#currentIndex ) {
			return;
		}

		this.#currentIndex = newIndex;
		this.navigate( newIndex );
	}

	private navigate( index: number ) {
		const items = this.querySelectorAll( '[data-carousel-item]' );
		const length = items.length;

		// check if items not available
		if ( 2 > length ) {
			return;
		}

		// get current item
		const previous = items[this.indexMinusOne( index, items.length, true )];
		const current = items[index];
		const next = items[this.indexPlusOne( index, items.length, true )];

		// set attributes
		// first reset all items
		items.forEach( ( item ) => {
			item.removeAttribute( 'data-carousel-item-current' );
			item.querySelector( 'img' )?.setAttribute( 'loading', 'lazy' );
		} );

		previous?.querySelector( 'img' )?.setAttribute( 'loading', 'eager' );
		next?.querySelector( 'img' )?.setAttribute( 'loading', 'eager' );

		current?.setAttribute( 'data-carousel-item-current', '' );
	}

	private indexMinusOne( i: number, maxValue: number, looping: boolean ): number {
		if ( 2 > maxValue ) {
			return 0;
		}

		let index = i;

		index--;

		if ( 0 > index ) {
			if ( looping ) {
				return maxValue - 1;
			}

			return 0;

		}

		return index;
	}

	private indexPlusOne( i: number, maxValue: number, looping: boolean ): number {
		if ( 2 > maxValue ) {
			return 0;
		}

		let index = i;

		index++;

		if ( index >= maxValue ) {
			if ( looping ) {
				return 0;
			}

			return maxValue - 1;

		}

		return index;
	}
}

customElements.define( 'mr-artwork-carousel', MrArtworkCarousel );
